import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29116513"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-menu-mob__container" }
const _hoisted_2 = { class: "nav-menu-mob__wallet" }
const _hoisted_3 = { class: "nav-menu-mob__lang" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_WalletMenu = _resolveComponent("WalletMenu")!
  const _component_Lang = _resolveComponent("Lang")!
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ReplenishIcon = _resolveComponent("ReplenishIcon")!
  const _component_ConclusionIcon = _resolveComponent("ConclusionIcon")!
  const _component_WalletsIcon = _resolveComponent("WalletsIcon")!
  const _component_HistoryIcon = _resolveComponent("HistoryIcon")!
  const _component_StructureIcon = _resolveComponent("StructureIcon")!
  const _component_SettingsIcon = _resolveComponent("SettingsIcon")!

  return (this.translateData)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)), ["self"])),
        class: "nav-menu-mob"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_CloseIcon, {
            onClick: _ctx.handleClose,
            class: "nav-menu-mob__close"
          }, null, 8, ["onClick"]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_WalletMenu)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Lang, { type: 'button' })
          ]),
          _createVNode(_component_router_link, {
            class: "nav-menu-mob__link",
            to: "/dashboard/home"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_HomeIcon),
              _createTextVNode(_toDisplayString(this.translateData.menu_dashboard), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            class: "nav-menu-mob__link",
            to: "/dashboard/replenishment"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ReplenishIcon),
              _createTextVNode(_toDisplayString(this.translateData.menu_deposit), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            class: "nav-menu-mob__link",
            to: "/dashboard/output"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ConclusionIcon),
              _createTextVNode(_toDisplayString(this.translateData.menu_withdrawal), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            class: "nav-menu-mob__link",
            to: "/dashboard/my-wallets"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_WalletsIcon),
              _createTextVNode(_toDisplayString(this.translateData.menu_wallets), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            class: "nav-menu-mob__link",
            to: "/dashboard/history"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_HistoryIcon),
              _createTextVNode(_toDisplayString(this.translateData.menu_history), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            class: "nav-menu-mob__link",
            to: "/dashboard/my-structure"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_StructureIcon),
              _createTextVNode(_toDisplayString(this.translateData.menu_my_structure), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            class: "nav-menu-mob__link",
            to: "/dashboard/settings"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SettingsIcon),
              _createTextVNode(_toDisplayString(this.translateData.settings), 1)
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}