
import { defineComponent } from 'vue'
import HomeIcon from '@/assets/svg/home-icon.svg?inline'
import ReplenishIcon from '@/assets/svg/replenish-icon.svg?inline'
import ConclusionIcon from '@/assets/svg/conclusion-icon.svg?inline'
import WalletsIcon from '@/assets/svg/wallets-icon.svg?inline'
import HistoryIcon from '@/assets/svg/history-icon.svg?inline'
import StructureIcon from '@/assets/svg/structure-icon.svg?inline'
import SettingsIcon from '@/assets/svg/settings-icon.svg?inline'
// import HelpIcon from '@/assets/svg/help-icon.svg?inline'

export default defineComponent({
  name: 'NavMenu',
  data () {
    return {
      translateData: {}
    }
  },
  components: {
    HomeIcon,
    ReplenishIcon,
    ConclusionIcon,
    WalletsIcon,
    HistoryIcon,
    StructureIcon,
    SettingsIcon
    // HelpIcon
  },
  mounted () {
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('menu_dashboard', 'menu_deposit', 'menu_withdrawal', 'menu_wallets', 'menu_history', 'settings', 'help', 'menu_my_structure')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
