
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WalletMenu',
  data () {
    return {
      translateData: {},
      statistics: {
        income: 0,
        available_for_conclusion: 0
      }
    }
  },
  mounted () {
    this.getDashboardStatistics()
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('earnings', 'menu_to_withdraw')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    getDashboardStatistics () {
      this.$store.dispatch('getPayments/getStatistics').then(
        (response) => {
          this.statistics = response
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
