<template>
  <div class="main">
    <Header />
    <div class="main__container">
      <NavMenu />
      <slot />
    </div>
    <Footer />
  </div>

</template>

<script>
import { defineComponent } from 'vue'
import Header from '@/components/sections/Header.vue'
import Footer from '@/components/molecules/FooterUT.vue'
import NavMenu from '@/components/molecules/NavMenu.vue'

export default defineComponent({
  name: 'main-template',
  components: {
    Header,
    Footer,
    NavMenu
  }
})
</script>

<style lang="scss" scoped>
.main {
  background: #fff;
  min-height: 100vh;

  &__container {
    display: flex;
  }
}
</style>
